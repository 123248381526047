<template>
  <component is="b-card">
    <b-row>
      <b-col>
        <b-col>
          <b-form-group :label="$t('Ref')" label-for="ref">
            <b-form-input disabled :value="quotation.ref" />
          </b-form-group>
          <b-form-group :label="$t('Start date')" label-for="ref">
            <flat-pickr v-model="startDate" class="form-control" />
          </b-form-group>
        </b-col>
      </b-col>
      <b-col>
        <b-col>
          <b-form-group :label="$t('Client')" label-for="ref">
            <b-form-input disabled :value="quotation.client.name" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('End date')" label-for="ref">
            <flat-pickr v-model="endDate" class="form-control" />
          </b-form-group>
        </b-col>
      </b-col>
    </b-row>
    <b-row class="pl-1 pr-1">
      <b-col>
        <b-form-group :label="$t('Select product')" label-for="product">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="products"
            label="product_shortcut"
            v-model="selectedProduct"
            input-id="product"
            @search="fetchProductsOptions"
            @input="setSelected"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-card no-body class="mb-2 mt-2">
      <b-table
        ref="refTable"
        class="position-relative"
        responsive
        :fields="['product', 'qte', 'actions']"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="fetchProductDesignation"
      >
        <template #cell(product)="data">
          {{ data.item.product_shortcut }}
        </template>
        <template #cell(qte)="data">
          <b-row>
            <b-col lg="4">
              <b-input-group :append="data.item.unity.name">
                <b-form-input
                  placeholder="Quantity"
                  v-model="quantities[data.index]"
                  type="number"
                  min="1"
                />
              </b-input-group>
            </b-col>
            <b-col lg="4">
              <b-input-group append="DH">
                <b-form-input
                  placeholder="Price"
                  :value="data.item.designation_price.price"
                  disabled
                />
              </b-input-group>
            </b-col>
            <b-col v-if="data.item.duplicated" style="margin-top: 8px">
              <feather-icon
                icon="AlertTriangleIcon"
                size="21"
                class="text-danger"
              />
            </b-col>
          </b-row>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click="deleteLine(data)"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </template>
      </b-table>

      <b-row class="mt-3">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="editQuotation"
          >
            {{ $t("Edit") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
  BInputGroup,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,
    BInputGroup,

    vSelect,
    flatPickr,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      items: [],
      products: [],
      quantities: [],
      quotation: null,
      callback: null,
      startDate: null,
      endDate: null,

      selectedProduct: null,
      selectedProducts: [],
    };
  },
  async created() {
    try {
      const res = await instance.get(
        `quotations/${this.$router.currentRoute.params.id}`
      );

      this.startDate = res.data.start_date;
      this.endDate = res.data.end_date;
      const res2 = await instance.get(
        "/parameters/product-combinations-prices/",
        {
          params: {
            sub_profile: res.data.client.sub_profile.id,
          },
        }
      );
      this.products = res2.data.results;
      this.quotation = res.data;

      const details = res.data.quotation_details.map((e) => ({
        id: e.product_combination.id,
        product_shortcut: e.product_combination.product_shortcut,
        designation_price: { price: e.price },
        unity: e.product_combination.unity,
      }));

      for (var detail of details) {
        this.items.push(detail);
      }

      for (var detail of res.data.quotation_details) {
        this.selectedProducts.push(detail.product_combination);
      }

      this.quantities = res.data.quotation_details.map((e) => e.quantity);

      setTimeout(() => {
        this.callback(this.items);
      }, 1);
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    setSelected(value) {
      if (!value) return;

      if (
        this.selectedProducts.filter((e) => e.id === this.selectedProduct.id)
          .length > 0
      ) {
        this.selectedProduct = null;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The product combination has already been selected"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      this.selectedProducts.push(this.selectedProduct);
      this.$refs["refTable"].refresh();
    },
    async fetchProductDesignation(ctx, callback) {
      this.callback = callback;
      if (!this.selectedProduct) {
        return;
      }
      try {
        callback(this.items);
        const response = await instance.put(
          `/parameters/product-combinations/${this.selectedProduct.id}`,
          {
            sub_profile: this.quotation.client.sub_profile.id,
          }
        );
        this.selectedProduct = null;
        this.items.push(response.data);
        this.quantities = [...this.quantities, "1"];

        setTimeout(() => {
          callback(this.items);
        }, 1);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    async editQuotation() {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to edit this quotation?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!result) {
        return;
      }

      try {
        const product_combinations = this.items.map((e) => parseInt(e.id));
        const prices = this.items.map((e) =>
          e.designation_price.price.toString()
        );

        const quantities = this.quantities.map((e) => e);

        await instance.put(`quotations/${this.quotation.id}`, {
          start_date: this.startDate,
          end_date: this.endDate,
          client_id: this.quotation.client.id,
          product_combinations,
          quantities,
          prices,
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Quotation has been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        if (err.response?.status === 400) {
          this.items = this.items.map((e) =>
            err.response.data.includes(e.id) ? { ...e, duplicated: true } : e
          );

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t(
                "you have products that belong to a ongoing quotation"
              ),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });

          this.callback(this.items);
          return;
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    deleteLine(data) {
      this.selectedProducts = this.selectedProducts.filter(
        (e) => e.id !== data.item.id
      );
      this.items = this.items.filter((e) => e.id !== data.item.id);

      this.quantities = this.quantities.filter(
        (e, index) => index !== data.index
      );

      this.callback(this.items);
    },

    fetchProductsOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get(
            "/parameters/product-combinations-prices/",
            {
              params: {
                search,
                sub_profile: this.quotation.client.sub_profile.id,
              },
            }
          );
          this.products = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
